// Usage: <script src="/embed.js"></script> in the HTML file. '/public/index.html'

function embedWidget(containerId, options) {
  const base_url = `https://assistant.zolaelectric.com`
  //const base_url = `http://localhost:3000`;
  const container = document.getElementById(containerId)

  if (!container) {
    console.error(`Container element with ID "${containerId}" not found.`)
    return
  }

  function getCookie(name) {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length))
    }
    return null
  }

  function getDomain() {
    const hostname = window.location.hostname
    if (hostname === 'localhost' || hostname.startsWith('127.0.0.1')) {
      return hostname
    }
    if (hostname.endsWith('.zolaelectric.com')) {
      return '.zolaelectric.com'
    }
    return hostname
  }

  function setCookie(name, value, days) {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    const domain = getDomain()
    // Ensure value is a string, but don't double-encode
    const stringValue = typeof value === 'string' ? value : JSON.stringify(value)
    let cookieString = `${name}=${encodeURIComponent(stringValue)}${expires}; path=/; domain=${domain}; SameSite=Lax`
    if (location.protocol === 'https:') {
      cookieString += '; Secure'
    }
    document.cookie = cookieString
  }

  const cookieValue = JSON.stringify(options.pageContext)
  setCookie('widgetPageContext', options.pageContext, 1)

  const defaultWidth = '16rem'
  const defaultHeight = '3rem'
  const expandedWidth = options.expandedWidth || '32rem'
  const expandedHeight = options.expandedHeight || '100vh'

  const iframe = document.createElement('iframe')
  iframe.src = `${base_url}/widget?context=${options.context}&userId=${options.userId}&conversationId=${
    options.conversationId
  }&initialState=${options.initialState === 'embedded' ? 'expanded' : options.initialState}`
  iframe.style.position = 'fixed'
  iframe.style.border = 'none'
  iframe.style.zIndex = '9999'

  if (options.initialState === 'embedded') {
    iframe.style.position = 'relative'
    iframe.style.zIndex = null
    iframe.style.width = '100%'
    iframe.style.height = '100%'
    iframe.style.top = '0'
    iframe.style.right = '0'
  } else if (options.initialState === 'expanded') {
    iframe.style.width = expandedWidth
    iframe.style.height = expandedHeight
    iframe.style.top = '0'
    iframe.style.right = '0'
  } else {
    iframe.style.width = options.width || defaultWidth
    iframe.style.height = options.height || defaultHeight
    iframe.style.bottom = '0'
    iframe.style.right = '1rem'
  }

  iframe.onload = function () {
    iframe.contentWindow.postMessage({ type: 'contextReady' }, '*') // Using '*' as the target origin
    const handleMessage = function (event) {
      if (event.data.type === 'widget-expand') {
        iframe.style.width = options.initialState === 'embedded' ? '100%' : expandedWidth
        iframe.style.height = options.initialState === 'embedded' ? '100%' : expandedHeight
        iframe.style.top = '0'
        iframe.style.right = '0'
      } else if (event.data.type === 'widget-collapse') {
        iframe.style.width = options.width || defaultWidth
        iframe.style.height = defaultHeight
        iframe.style.bottom = '0'
        iframe.style.right = '1rem'
        iframe.style.top = 'auto'
      }
    }

    window.addEventListener('message', handleMessage)
  }

  container.appendChild(iframe)
}

export function createWidgetContainer() {
  var containerId = 'widget-container'
  var options = {
    userId: 'defaultUserId',
    context: 'vision',
    width: '20rem',
    height: '5rem',
    expandedWidth: '40rem',
    expandedHeight: '100vh',
    initialState: 'collapsed',
    pageContext: {},
  }

  var container = document.getElementById(containerId)
  if (container) {
    options = {
      ...options,
      userId: container.getAttribute('data-userid') || options.userId,
      initialState: container.getAttribute('data-initialstate') || options.initialState,
    }

    const systemData = container.getAttribute('data-system-data')
    if (systemData) {
      try {
        options.pageContext.systemData = JSON.parse(systemData)
      } catch (e) {
        console.error('Failed to parse system data:', e)
      }
    }
  }

  if (window.widgetOptions) {
    options = { ...options, ...window.widgetOptions }
  }

  if (window.widgetPageContext) {
    options.pageContext = window.widgetPageContext
  } else if (options.pageContext) {
    options.pageContext = options.pageContext
  }

  embedWidget(containerId, options)
}
